<template>
  <div class="container-top">
    <div class="container position-relative">
      <component :is="componentName"
                 :ref="componentName"
                 :order.sync="order"
                 @prev="clickPrev"
                 @next="clickNext"></component>
    </div>
  </div>
</template>
<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import ServiceCreateStep1 from "./ServiceCreate/ServiceCreateStep1";
  import ServiceCreateStep2 from "./ServiceCreate/ServiceCreateStep2";
  import ServiceCreateStep3 from "./ServiceCreate/ServiceCreateStep3";
  import ServiceCreateStep4 from "./ServiceCreate/ServiceCreateStep4";
  import ServiceCreateStep5 from "./ServiceCreate/ServiceCreateStep5";
  import ServiceCreateStep6 from "./ServiceCreate/ServiceCreateStep6";
  import PageMixin from "../../mixins/PageMixin";
  export default {
    name: "ServiceCreate",
    components: {ServiceCreateStep6, ServiceCreateStep5, ServiceCreateStep4, ServiceCreateStep3, ServiceCreateStep2, ServiceCreateStep1},
    mixins: [
      UserAPIMixin,
      PageMixin
    ],
    created() {
      this.login_required = true;
      this.order.name = this.user.name;
      this.order.phone = this.user.phone;
      this.order.email = this.user.email;
      this.sbRequest();
    },
    mounted() {
      this.$nextTick(() => {
        setTimeout(() => {
          document.getElementById('ch-plugin').style.display = 'none';
        }, 1000);
      });
    },
    beforeDestroy() {
      document.getElementById('ch-plugin').style.display = '';
    },
    data() {
      return {
        step: 1,
        order: {
          type: 'theme',
          service_name: '',
          key: '',
          order_product: undefined,
          total_price: 0,
          subscribe_price: 0,
          hosting: undefined,
          hosting_id: '',
          name: '',
          phone: '',
          email: ''
        }
      }
    },
    watch: {
      '$route'(n,o){
        if(!o.query.step && n.query.step) {
          this.step = n.query.step;
        } else {
          const oldStep = Number(o.query.step);
          const newStep = Number(n.query.step);
          if(newStep !== this.step && oldStep < newStep) {
            this.clickNext();
          } else if(oldStep > newStep){
            this.clickPrev();
          }
        }
      }
    },
    computed: {
      componentName() {
        return `ServiceCreateStep${this.step}`
      }
    },
    methods: {
      sbRequest() {
        if(this.$route.query.result) {
          let result = JSON.parse(this.$route.query.result);
          let resultCode = result.resultCode;
          if (resultCode !== '00' && resultCode !== '0000') {
            this.order = this.$store.getters.basket;
            this.$router.push('/service_create?step=6');
            this.toast('입력하신 카드정보가 잘못되었습니다. 다시 입력하시기 바랍니다.');
          } else {
            this.$router.push(`/bill_done?result=${JSON.stringify(result)}`);
          }
        } else if(!this.$route.query.step) {
          this.$router.push(`service_create?step=${this.step}`);
        }
      },
      clickPrev() {
        this.step--;
        if(this.step===2) {
          this.$nextTick(()=>{
            this.$refs.ServiceCreateStep2.step = 4;
          })
        }
      },
      async clickNext() {
        if (this.step===2) {
          // order history의 최신 next_date
        }

        if (this.step===6) {
          let basket = this.cloneItem(this.order);
          if(basket.hosting) basket.hosting_id = basket.hosting.id;
          if(basket.care_service) basket.care_service_id = basket.care_service.id;

          basket.title = '런치팩 플랫폼 서비스 개설';
          basket.price = basket.subscribe_price;
          this.request.user.post('launchpack/v1/service/order', basket).then(res=>{
            this.$store.commit('setLoading', false);
            if (res.status === 200) {
              this.routerPush('/mypage/my_service');
            }
          }).catch(()=>{
            this.$store.commit('setLoading', false);
          });
        } else {
          this.step++;
          this.$router.push(`service_create?step=${this.step}`);
        }
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @media (min-width: 1024px)
    .container-top
      padding-top 160px
  @media (max-width: 1024px)
    .container-top
      padding-bottom 0
    .container
      padding 0
      height 100vh

</style>
